import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
	MDBInput,
	MDBValidation,
	MDBValidationItem,
	MDBTextArea
} from 'mdb-react-ui-kit';

import rest_api from '../api/boafo';


function Create() {

	// Making usestate for setting and
	// fetching a value in jsx

	const [formValue, setFormValue] = useState({
		clerk_name: '',
		clerk_description: '',
	});

	const onChange = (e: any) => {
		setFormValue({ ...formValue, [e.target.name]: e.target.value });
	};

	// Using useNavigation for redirecting to pages
	let history = useNavigate();

	// Function for creating a post clerk
	const handelSubmit = async (event) => {
		event.preventDefault();
		if (formValue.clerk_name.length > 0 && formValue.clerk_description.length > 0) {
			await rest_api.post('api/v1/clerk', formValue)
			// redirecting to clerk dashboard
			history('/clerk')
		}
		else {
			alert("Missing required information")
		}
	}

	return (
		<div className="container">

			<MDBValidation className='row g-3' isValidated onSubmit={handelSubmit}>
				<MDBValidationItem feedback='Please enter a Clerk Name.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.clerk_name}
						type='text'
						className='form-control'
						name='clerk_name'
						onChange={onChange}
						id='validationClerkName'
						required
						placeholder='clerk Name'
					/>
				</MDBValidationItem>
				<MDBValidationItem feedback='Please enter a Clerk Description.' invalid className='mb-3 pb-1'>
					<MDBTextArea
						value={formValue.clerk_description}
						type='textarea'
						className='form-control'
						name='clerk_description'
						onChange={onChange}
						id='validationClerkDescription'
						required
						placeholder='Clerk Description'
					/>
				</MDBValidationItem>

				<Stack spacing={2} direction="row">
					<Button variant="contained" color="success" type='submit'>Submit</Button>

					<NavLink end to='/clerk' >
						<Button variant="contained" color="primary" type='submit'>Cancel</Button>
					</NavLink>
				</Stack>
			</MDBValidation>

		</div>
	)
}

export default Create
