// important imports
import React from 'react'
import { BrowserRouter as Router, Route, Routes }
  from 'react-router-dom';
import './App.css';

import Case from './components/Case';
import CreateCase from './components/Case/Create';
import Clerk from './components/Clerk';
import CreateClerk from './components/Clerk/Create';
import Doctor from './components/Doctor';
import CreateDoctor from './components/Doctor/Create'
import Patient from './components/Patient';
import CreatePatient from './components/Patient/Create';

import Vaccine from './components/Vaccine';
import Prescription from './components/Prescription';
import LaboratoryRecord from './components/LaboratoryRecord';
import Medicine from './components/Medicine';
import CreateMedicine from './components/Medicine/Create';
import Consultation from './components/Consultation';
import Allergy from './components/Allergy';
import Appointment from './components/Appointment';
import Billing from './components/Billing';
import Resource from './components/Resource'
import Home from './components/Home';
import Account from './components/Account';
import Contact from './components/Contact';
import Header from './components/Common/Header';
// import Footer from './components/Common/Footer';
import NotFoundPage from './components/Common/404';
import Login from './components/Common/Login';
import Logout from './components/Common/Logout';
import { useAuth0 } from "@auth0/auth0-react";
import { DoctorContextProvider } from './components/Doctor/Context'
import Report from './components/Report';

function App() {

  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div className="container">Loading ...</div>;
  }

  if (!isAuthenticated) {
    return (<div className="container">
      login required
      <Login />
    </div>
    );
  }

  return (
    <>
      <DoctorContextProvider>
        <Router>
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/account' element={<Account />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/resource' element={<Resource />} />
            <Route path='/report' element={<Report />} />
            <Route path="*" element={<NotFoundPage />} />

            <Route path='/allergy' element={<Allergy />} />
            <Route path='/appointment' element={<Appointment />} />
            <Route path='/billing' element={<Billing />} />
            <Route path='/case' element={<Case />} />
            <Route path='/clerk' element={<Clerk />} />
            <Route path='/doctor' element={<Doctor />} />
            <Route path='/patient' element={<Patient />} />
            <Route path='/consultation' element={<Consultation />} />
            <Route path='/laboratory_record' element={<LaboratoryRecord />} />
            <Route path='/prescription' element={<Prescription />} />
            <Route path='/medicine' element={<Medicine />} />
            <Route path='/vaccine' element={<Vaccine />} />

            <Route path='/case/create' element={<CreateCase />} />
            <Route path='/clerk/create' element={<CreateClerk />} />
            <Route path='/doctor/create' element={< CreateDoctor />} />
            <Route path='/medicine/create' element={<CreateMedicine />} />
            <Route path='/patient/create' element={< CreatePatient />} />
          </Routes>
        </Router>
      </DoctorContextProvider>

    </>
  );

}

export default App;
