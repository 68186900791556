import React, { useState, useContext } from "react";
import { Context } from '../Doctor/Context'

const Dictation = ({ patient }) => {
    const [visitReports, setVisitReports] = useState([]);
    const { id, visits, vitals, appointment } = patient;
    const { bloodPressure, temperature, heartRate, weight } = vitals;
    const { date, type, status, reason, stage } = appointment;
    let doctor_context = useContext(Context)

    const [newVisitReport, setNewVisitReport] = useState({
        dateOfVisit: date,
        reasonForVisit: reason,
        doctor: doctor_context._id,
        diagnosis: "",
        treatment: "",
        followUpInstructions: "",
    });

    const handleChange = (event) => {
        setNewVisitReport({
            ...newVisitReport,
            [event.target.name]: event.target.value,
        });
    };

    const addVisitReport = (event) => {
        event.preventDefault();
        setVisitReports([...visitReports, newVisitReport]);
        setNewVisitReport({
            dateOfVisit: date,
            reasonForVisit: reason,
            doctor: doctor_context._id,
            diagnosis: "",
            treatment: "",
            followUpInstructions: "",
        });
    };

    return (
        <div>
            <form onSubmit={addVisitReport}>
                <div>
                    <input
                        type="text"
                        placeholder="Diagnosis"
                        id="diagnosis"
                        name="diagnosis"
                        value={newVisitReport.diagnosis}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        placeholder="Treatment"
                        id="treatment"
                        name="treatment"
                        value={newVisitReport.treatment}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <textarea
                        placeholder="Follow-up Instructions"
                        id="followUpInstructions"
                        name="followUpInstructions"
                        value={newVisitReport.followUpInstructions}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit">Add</button>
            </form>
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Date of Visit</th>
                        <th>Reason for Visit</th>
                        <th>Doctor</th>
                        <th>Diagnosis</th>
                        <th>Treatment</th>
                        <th>Follow-up Instructions</th>
                    </tr>
                </thead>
                <tbody>
                    {visitReports.map((visitReport, index) => (
                        <tr key={index}>
                            <td>{visitReport.dateOfVisit}</td>
                            <td>{visitReport.reasonForVisit}</td>
                            <td>{visitReport.doctor}</td>
                            <td>{visitReport.diagnosis}</td>
                            <td>{visitReport.treatment}</td>
                            <td>{visitReport.followUpInstructions}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Dictation;
