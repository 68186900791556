import React, { useState, useEffect, useMemo } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import MaterialReactTable from 'material-react-table';

import rest_api from '../api/boafo';


const LaboratoryRecord = () => {
	const columns = useMemo(
		() => [{
			header: 'Laboratory Record Name',
			id: 'lab_name',
		},
		{
			header: 'Lab Detail',
			id: 'lab_detail',
		},
		{
			header: 'Lab Result',
			id: 'lab_result',
		},
		{
			header: 'Lab Detail',
			id: 'lab_detail',
		},
		{
			header: 'Released Date',
			id: 'date_released',
		},
		], [],
	);

	const [laboratory_record_obj, set_laboratory_record_obj] = useState([null]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const laboratory_record_data_array = useMemo(() => laboratory_record_obj.map(item => {
		const laboratory_records = (item);
		return laboratory_records
	}), [laboratory_record_obj])

	const catchAllLaboratoryRecords = () => {
		rest_api.get(`api/v1/laboratory_record?`)
			.then(response => {
				if (response.status === 200 && response != null) {
					setIsLoaded(true);
					set_laboratory_record_obj(response.data.laboratory_record);
				} else {
					setError(error)
				}
			})
			.catch(error => {
				setError(error)
			})
	};

	useEffect(() => {
		catchAllLaboratoryRecords()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (error) {
		return (
			<div className="container">
				<center>
					<div className="error" style={{ color: "red" }}>
						<p>Error message: {error.message}</p>
					</div>
				</center>
			</div>
		);

	} else if (!isLoaded) {
		return (
			<div className="container">
				<center>
					<div className="loading" style={{ color: "#F58634" }}>
						<p>Loading...</p>
					</div>
				</center>
			</div>
		);
	} else {
		return (
			<>
				<div className="container">
					<MaterialReactTable
						columns={columns}
						data={laboratory_record_data_array}
						enableRowVirtualization
						muiTableContainerProps={{ sx: { maxHeight: '720px', maxWidth: '1200px' } }}
						virtualizerProps={{ overscan: 20 }}
						positionToolbarAlertBanner="bottom"
						enableColumnOrdering
						enableColumnResizing

					/>
				</div>

			</>

		)
	}
}

export default LaboratoryRecord