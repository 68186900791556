import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import ClerksManager from '../Clerk'
import Doctor from '../Doctor';
import { useAuth0 } from "@auth0/auth0-react";


const Account = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const [isProfileChecked, setProfileChecked] = useState(false);
    const [isApplicationChecked, setApplicationChecked] = useState(false);

    if (isLoading) {
        return (
            <>
                <Container maxwidthlg='true' style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                    padding: '10px',
                    backgroundColor: '#F8F8F8'
                }}>
                    <Box sx={{
                        height: 'auto',
                        color: '#000080',
                    }} >
                        <p>Loading...</p>
                    </Box>
                </Container>
            </>
        )
    }

    return (
        isAuthenticated && (
            <>
                <Container maxwidthlg='true'
                    style={{
                        marginTop: '2vh',
                        boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                        padding: '10px',
                        backgroundColor: '#F8F8F8',
                    }}>

                    <div>
                        <FormControlLabel style={{ margin: "0 20px 10px" }}
                            control={<Switch size='small' color='warning'
                                checked={isProfileChecked} onChange={() => {
                                    setProfileChecked((prev) => !prev);
                                }} />}
                            label="Edit Profile"
                        />
                        <Collapse in={isProfileChecked}>
                            <Doctor />
                        </Collapse>

                        <FormControlLabel style={{ margin: "0 20px 10px" }}
                            control={<Switch size='small' color='warning'
                                checked={isApplicationChecked} onChange={() => {
                                    setApplicationChecked((prev) => !prev);
                                }} />}
                            label="Manage Clerk"
                        />
                        <Collapse in={isApplicationChecked}>
                            <ClerksManager />
                        </Collapse>
                    </div>
                </Container>

            </ >
        )

    )
}

export default Account