import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const TStatistic = ({ data }) => {
  const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
  const standardDeviation = Math.sqrt(
    data.reduce((sum, value) => sum + (value - mean) ** 2, 0) / (data.length - 1)
  );
  const tStatistic = mean / (standardDeviation / Math.sqrt(data.length));

  return (
    <div className="generic-card">
      <LineChart width={600} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
      <p>Mean: {mean.toFixed(2)}</p>
      <p>Standard Deviation: {standardDeviation.toFixed(2)}</p>
      <p>T-statistic: {tStatistic.toFixed(2)}</p>
    </div>
  );
};

export default TStatistic;
