import React from "react";
// Importing material UI components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Container from '@mui/material/Container';
// Importing material UI icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CurrencyExchange } from "@mui/icons-material";
import MedicalBilling from "./MedicalBilling";

export default function ListComponent() {
	const [open, setOpen] = React.useState(true);
	const handleClick = () => {
		setOpen(!open);
	};
	return (
		<>
			<Container maxwidthlg='true' style={{
				marginTop: '2vh',
				boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
				padding: '10px',
				backgroundColor: '#F8F8F8',
			}}>

				<List
					component="nav"
					aria-labelledby="nested-list-subheader"
				>
					<ListItem button dense onClick={handleClick} sx={{
						bgcolor: '#E8E8E8',
						border: '1px solid #cce2ff'
					}}>
						<ListItemIcon sx={{ color: '#4040a1' }}>
							<CurrencyExchange />
						</ListItemIcon>
						<ListItemText primary="Billing" />
						{/*code to open and closed list*/}
						{open ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List>
							<MedicalBilling />
						</List>
					</Collapse>
				</List>
			</Container>
		</>
	);
}
