import React from 'react';
import { useState, useContext } from "react"
import Switch from '@mui/material/Switch'
import Collapse from "@mui/material/Collapse"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from '@mui/material/Typography'
import { useAuth0 } from "@auth0/auth0-react"
import { Context } from '../Doctor/Context'
import rest_api from '../api/boafo'

const Doctor = () => {

	const [debug, setDebug] = useState(true);

	const { user, } = useAuth0();
	let profile = useContext(Context)
	let dev = useState(false)

	const handleDebug = () => {
		setDebug(!debug);
	};

	const defaultValues = {
		title: '',
		first_name: user.given_name,
		middle_name: '',
		last_name: user.family_name,
		picture: user.picture,
		date_of_birth: '',
		gender: '',
		email: user.email,
		phone_number: [''],
		address: '',
		city: '',
		region: '',
		country: '',
		active: '',
		identities: [],
		field_of_specialization: '',
	};

	const [formValues, setFormValues] = useState(defaultValues);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	// Function for Updating Person
	const handleSubmit = async (event) => {
		event.preventDefault()
		if (profile._id) {
			formValues._id = profile._id
			await rest_api.put('api/v1/doctor', formValues)
		} else {
			await rest_api.post('api/v1/doctor', formValues)
		}

	}
	return (
		<div style={{ display: "flex" }}>
			<form onSubmit={handleSubmit}
				style={{
					marginTop: "0 0 20px 0",
					padding: "10px"
				}}>
				<div>
					<select
						id="field_of_specialization-select"
						name="field_of_specialization"
						value={profile.field_of_specialization ? profile.field_of_specialization : formValues.field_of_specialization}
						onChange={handleInputChange}
						required
					>
						<option value="select">
							-- Select specialization --
						</option>
						<option value="physician">
							Physician
						</option>
						<option value="cardiologist">
							Cardiologist
						</option>
						<option value="internist">
							Internist
						</option>
						<option value="obstetrician">
							Obstetrician and gynecologists
						</option>
						<option value="neurologist">
							Neurologist
						</option>
						<option value="radiologist">
							Radiologist
						</option>
						<option value="anesthesiologist">
							Anesthesiologist
						</option>
						<option value="pediatrician">
							Pediatrician
						</option>
					</select>
				</div>
				<div >
					<select
						id="tite-select"
						name="title"
						value={profile.title ? profile.title : formValues.title}
						onChange={handleInputChange}
						required
					>
						<option value="select">
							-- Select title --
						</option>
						<option value="mr">
							Mr
						</option>
						<option value="mrs">
							Mrs
						</option>
						<option value="ms">
							Ms
						</option>
					</select>
				</div>
				<div>
					<input
						id="first-name-input"
						name="first_name"
						placeholder="First Name"
						type="text"
						value={formValues.first_name}
						onChange={handleInputChange}
						required
					/>
				</div>
				<div>
					<input
						id="middle-name-input"
						name="middle_name"
						placeholder="Middle Name"
						type="text"
						value={profile.middle_name ? profile.middle_name : formValues.middle_name}
						onChange={handleInputChange}
					/>
				</div>
				<div>
					<input
						id="last-name-input"
						name="last_name"
						placeholder="Last Name"
						type="text"
						value={formValues.last_name}
						onChange={handleInputChange}
						required
					/>
				</div>
				<div>
					<input
						id="address-number-input"
						name="address"
						placeholder="Address"
						type="text"
						value={profile.address ? profile.address : formValues.address}
						onChange={handleInputChange}
						required
					/>
				</div>
				<div>
					<input
						id="city-number-input"
						name="city"
						placeholder="City"
						type="text"
						value={profile.city ? profile.city : formValues.city}
						onChange={handleInputChange}
						required
					/>
				</div>
				<div>
					<select
						label="region"
						id="region-select"
						name="region"
						value={profile.region ? profile.region : formValues.region}
						onChange={handleInputChange}
						required
					>
						<option value="select">
							-- Select region --
						</option>
						<option value="acc">
							Greater Accra
						</option>
						<option value="atl">
							Georgia
						</option>
						<option value="lg">
							9ja
						</option>
					</select>
				</div>
				<div>
					<select
						id="country-select"
						placeholder="Country"
						name="country"
						value={profile.country ? profile.country : formValues.country}
						onChange={handleInputChange}
						required
					>
						<option value="select">
							-- Select country --
						</option>
						<option value="usa">
							USA
						</option>
						<option value="gh">
							Ghana
						</option>
						<option value="ng">
							Nigeria
						</option>
					</select>
				</div>
				<div>
					<input
						id="phone-number-input"
						name="phone_number"
						placeholder="Phone Number"
						type="tel"
						value={profile.phone_number ? profile.phone_number : formValues.phone_number}
						onChange={handleInputChange}
						required
					/>
				</div>
				<div>
					<input
						type="radio"
						id="male"
						name="gender"
						value="male"
						checked={formValues.gender === 'male'}
						onChange={handleInputChange}
					/>
					<label htmlFor="male">&nbsp;Male</label>
				</div>
				<div>
					<input
						type="radio"
						id="female"
						name="gender"
						value="female"
						checked={formValues.gender === 'female'}
						onChange={handleInputChange}
					/>
					<label htmlFor="female">&nbsp;Female</label>
				</div>
				<div>
					<input
						type="radio"
						id="other"
						name="gender"
						value="other"
						checked={formValues.gender === 'other'}
						onChange={handleInputChange}
					/>
					<label htmlFor="other">&nbsp;Other</label>
				</div>
				<div>

					<button type="submit">
						{profile._id ? "Update" : "Setup"}
					</button>

				</div>
			</form >
			<div style={{
				marginTop: "0 0 20px 0",
				padding: "10px"
			}}>
				{
					dev[0] ?
						<FormControlLabel

							control={<Switch size='small' color='warning' checked={debug} onChange={() => {
								handleDebug((prev) => !prev);
							}}
							/>}
						/>
						: ''
				}

				<Collapse in={debug} className="generic-card"
					style={{
						color: "red",
						backgroundColor: '#f5faff'
					}}>
					<p><i>debug: </i></p>
					{Object.entries(profile).map(([key, value], i) => {
						return (
							<Typography component={'span'} gutterBottom variant='body2'>
								<li key={i}>
									<span>{key}: {value}</span>
								</li>
							</Typography>
						);
					})}
				</Collapse>
			</div>
		</div >

	);
};
export default Doctor;