import axios from 'axios';

const token = process.env.REACT_APP_API_KEY 

// Create instance called instance
export default axios.create({
    baseURL: 'https://boafo.com/',
    headers: {
        'Content-type': "application/json",
        'Authorization': token,
        
    },
});
