import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAuth0 } from "@auth0/auth0-react";


const Home = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return (
            <>
                <CssBaseline />
                <Container maxwidthlg='true' style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                }}>
                    <Box sx={{
                        height: 'auto',
                        color: '#000080',
                    }} >
                        <p>Loading...</p>
                    </Box>
                </Container>
            </>
        )
    }

    return (

        isAuthenticated && (
            <>
                <CssBaseline />
                <Container maxwidthlg='true' style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                }}>
                    <Box sx={{
                        height: 'auto',
                        color: '#000080',
                    }} >

                        <Typography textAlign="center" color="red" variant="h2">
                            !404
                        </Typography>

                        <Typography textAlign="center" color="#F58634" variant="h5" gutterBottom>
                            The resource is not available to you at this time.
                        </Typography>
                    </Box>
                </Container>

            </ >
        )
    )
}

export default Home