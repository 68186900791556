import React, { useState } from "react";

const samplePatients = [
  { id: 1, name: "John Doe", visit: "2022-01-01", billingCode: "A001", billed: "$6.0", status: "open" },
  { id: 2, name: "Jane Doe", visit: "2022-02-01", billingCode: "B001", billed: "$5.0", status: "open" },
  { id: 3, name: "Jim Smith", visit: "2022-03-01", billingCode: "C001", billed: "$1.0", status: "open" },
  { id: 4, name: "Sara Lee", visit: "2022-04-01", billingCode: "D001", billed: "$5.0", status: "forgiven" },
  { id: 5, name: "Bob Johnson", visit: "2022-05-01", billingCode: "E001", billed: "$3.0", status: "open" },
  { id: 6, name: "Tommy Lee", visit: "2022-06-01", billingCode: "F001", billed: "$3.0", status: "open" },
  { id: 7, name: "Harry Potter", visit: "2022-07-01", billingCode: "G001", billed: "$3.0", status: "closed" },
  { id: 8, name: "Ron Weasley", visit: "2022-08-01", billingCode: "H001", billed: "$3.0", status: "closed" },
  { id: 9, name: "Hermione Granger", visit: "2022-09-01", billingCode: "I001", billed: "$3.0", status: "open" },
  { id: 10, name: "Draco Malfoy", visit: "2022-10-01", billingCode: "J001", billed: "$1.0", status: "open" },
];

function MedicalBilling() {
  const [patients, setPatients] = useState(samplePatients);

  const handleEdit = (id) => {
    const updatedPatients = patients.map((patient) => {
      if (patient.id === id) {
        // make changes to this patient's information
      }
      return patient;
    });
    setPatients(updatedPatients);
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Visit Date</th>
          <th>Billing Code</th>
          <th>Billed Amount</th>
          <th>Billed Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {patients.map((patient) => (
          <tr key={patient.id}>
            <td>{patient.name}</td>
            <td>{patient.visit}</td>
            <td>{patient.billingCode}</td>
            <td>{patient.billed}</td>
            <td>{patient.status}</td>
            <td>
              <button onClick={() => handleEdit(patient.id)}>Edit</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default MedicalBilling;
