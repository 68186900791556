import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import { useAuth0 } from "@auth0/auth0-react";

const Contact = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const [isContactChecked, setContactChecked] = useState(false);
    const [isHelpChecked, setHelpChecked] = useState(false);

    if (isLoading) {
        return (
            <>
                <Container maxwidthlg='true' style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                    padding: '40px',
                    backgroundColor: '#F8F8F8'
                }}>
                    <Box sx={{
                        height: 'auto',
                        color: '#000080',
                    }} >
                        <p>Loading...</p>
                    </Box>
                </Container>
            </>
        )
    }

    return (
        isAuthenticated && (
            <>
                <Container maxwidthlg='true' style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                    padding: '10px',
                    backgroundColor: '#F8F8F8'
                }}>
                    <Box sx={{
                        height: 'auto',
                        color: '#606060',
                    }} >
                        <div style={{ display: 'block', padding: 30 }}>
                            <FormControlLabel
                                control={<Switch checked={isContactChecked} onChange={() => {
                                    setContactChecked((prev) => !prev);
                                }} />}
                                label="Contact Resource"
                            />
                            <div style={{
                                display: 'flex',
                            }}>
                                <Collapse in={isContactChecked}>
                                    <Paper
                                        elevation={5}
                                        style={{
                                            margin: 40, padding: 30,
                                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
                                        }} >
                                        <Typography component={'span'} gutterBottom variant='body1'>
                                            Contact Component
                                        </Typography>
                                    </Paper>
                                </Collapse>
                            </div>

                            <FormControlLabel
                                control={<Switch checked={isHelpChecked} onChange={() => {
                                    setHelpChecked((prev) => !prev);
                                }} />}
                                label="Help"
                            />
                            <div style={{
                                display: 'flex',
                            }}>
                                <Collapse in={isHelpChecked}>
                                    <Paper
                                        elevation={5}
                                        style={{
                                            margin: 40, padding: 30,
                                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
                                        }} >
                                        <Typography component={'span'} gutterBottom variant='body1'>
                                            Help Component
                                        </Typography>
                                    </Paper>
                                </Collapse>
                            </div>
                        </div>
                    </Box>
                </Container>
            </ >
        )
    )
}

export default Contact