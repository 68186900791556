import React, { useState, useContext } from "react"
// Importing material UI components
import Container from '@mui/material/Container'
// Importing material UI icons
import { Context } from '../Doctor/Context'
import VisitReport from './VisitReport'

export default function ListComponent() {
    let showDeskstop = useState(true)

    let profile = useContext(Context)

    if (showDeskstop) {
        return (
            <>
                <Container maxWidthXl='true'
                    style={{
                        padding: '6px',
                    }}>
                    <div>
                        <VisitReport />
                    </div>
                </Container>
            </>
        );
    } else {
        return (
            <>
                <Container maxWidthXl='true'
                    style={{
                        marginTop: '2vh',
                        padding: '6px',
                    }}>
                    <div>
                        <center>
                            <p>loading...</p>
                        </center>
                    </div>
                </Container>
            </>
        );
    }
}
