import React, { useState, useEffect } from "react";
import PatientListDashboard from './PatientListDashboard';

const VisitReport = () => {
    const [visitReports, setVisitReports] = useState([]);
    // const [patientsData, setPatientsData] = useState([]);


    const [newVisitReport, setNewVisitReport] = useState({
        patientName: "",
        dateOfVisit: "",
        reasonForVisit: "",
        doctor: "",
        diagnosis: "",
        treatment: "",
        followUpInstructions: "",
    });

    const handleChange = (event) => {
        setNewVisitReport({
            ...newVisitReport,
            [event.target.name]: event.target.value,
        });
    };

    const addVisitReport = (event) => {
        event.preventDefault();
        setVisitReports([...visitReports, newVisitReport]);
        setNewVisitReport({
            patientName: "",
            dateOfVisit: "",
            reasonForVisit: "",
            doctor: "",
            diagnosis: "",
            treatment: "",
            followUpInstructions: "",
        });
    };

    // useEffect(() => {
    //     // Fetch patient data from API
    //     fetch('https://boafo.com/api/v1/patient')
    //         .then(response => response.json())
    //         .then(data => setPatientsData(data))
    //         .catch(error => console.log(error));
    // }, []);

    const patients = [
        {
            id: 1, name: 'Mekenna Boafo',
            photo: './img/mekenna.jpeg',
            dob: '12/24/1980',
            appointment: {
                date: '02-21 2:00pm',
                type: 'In-person',
                doctor: 'Dr Abena Lee',
                status: 'pending',
                reason: 'General Care',
                stage: 'apt'
            },
            vitals: {
                bloodPressure: '120/80',
                temperature: '98.6°F',
                heartRate: '70bpm',
                weight: '120lb'
            }, visits: [
                {
                    date: '2022-01-01',
                    reason: 'Constipation',
                    diagnosis: 'Not eating enough salad',
                    prescription: 'salad 1 leaf per meal'
                },
                {
                    date: '2021-10-15',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }]
        },
        {
            id: 2,
            name: 'Annalise Boafo',
            photo: './img/profile.jpeg',
            dob: '08/10/1994',
            appointment: {
                date: '04-30  1:00pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'confirm',
                reason: 'Runny eye',
                stage: 'consultation'
            },
            vitals: {
                bloodPressure: '130/90',
                temperature: '99.1°F',
                heartRate: '72bpm',
                weight: '143lb'
            },
            visits: [
                {
                    date: '2022-02-10',
                    reason: 'Excessive fluid in eye',
                    diagnosis: 'Crying',
                    prescription: 'candy, 1 tablet per month'
                },
                {
                    date: '2021-11-20',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }
            ]
        },
        {
            id: 3,
            name: 'Ama Johnson',
            photo: 'https://randomuser.me/api/portraits/men/3.jpg',
            dob: '01/10/1992',
            appointment: {
                date: '04-30  12:00pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Neck pain',
                stage: 'consultation'
            },
            vitals: {
                bloodPressure: '195/120',
                temperature: '105.4°F',
                heartRate: '68bpm',
                weight: '320lb'
            },
            visits: [
                {
                    date: '2022-01-25',
                    reason: 'Sore throat',
                    diagnosis: 'Viral pharyngitis',
                    prescription: 'Throat lozenges, Gargling with warm water'
                },
                {
                    date: '2021-09-01',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }
            ]
        },
        {
            id: 4,
            name: 'Kwabena Davis',
            photo: 'https://randomuser.me/api/portraits/women/4.jpg',
            dob: '01/12/1995',
            appointment: {
                date: '12-01 3:00pm',
                type: 'In-person',
                doctor: 'Dr Abena Lee',
                status: 'confirmed',
                reason: 'Cant sleep',
                stage: 'consultation'
            },
            vitals: {
                bloodPressure: '120/80',
                temperature: '98.9°F',
                heartRate: '111bpm',
                weight: '220lb'
            },
            visits: [
                {
                    date: '2022-01-15',
                    reason: 'Headache',
                    diagnosis: 'Tension headache',
                    prescription: 'Acetaminophen 500mg, Rest'
                },
                {
                    date: '2021-12-05',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }
            ]
        },
        {
            id: 5, name: 'Kwame Doe',
            photo: 'https://randomuser.me/api/portraits/men/6.jpg',
            dob: '04/15/1998',
            appointment: {
                date: '04-30  2:30pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Hungry',
                stage: 'apt'
            },
            vitals: {
                bloodPressure: '120/80',
                temperature: '98.6°F',
                heartRate: '70bpm',
                weight: '420lb'
            }, visits: [
                {
                    date: '2022-01-01',
                    reason: 'Cough and cold',
                    diagnosis: 'Upper respiratory infection',
                    prescription: 'Ibuprofen 400mg, Sudafed'
                },
                {
                    date: '2021-10-15',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }]
        },
        {
            id: 6,
            name: 'Kojo Smith',
            photo: 'https://randomuser.me/api/portraits/women/7.jpg',
            dob: '05/10/2004',
            appointment: {
                date: '04-30  12:10pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Too Lazy',
                stage: 'apt'
            },
            vitals: {
                bloodPressure: '130/90',
                temperature: '99.1°F',
                heartRate: '72bpm',
                weight: '100lb'
            },
            visits: [
                {
                    date: '2022-02-10',
                    reason: 'Back pain',
                    diagnosis: 'Muscle strain',
                    prescription: 'Naproxen 500mg, Stretching exercises'
                },
                {
                    date: '2021-11-20',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }
            ]
        },
        {
            id: 7,
            name: 'Yaw Johnson',
            photo: 'https://randomuser.me/api/portraits/men/8.jpg',
            dob: '02/10/2015',
            appointment: {
                date: '04-30  11:00am',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Bored',
                stage: 'apt'
            },
            vitals: {
                bloodPressure: '125/80',
                temperature: '98.4°F',
                heartRate: '68bpm',
                weight: '300lb'
            },
            visits: [
                {
                    date: '2022-01-25',
                    reason: 'Sore throat',
                    diagnosis: 'Viral pharyngitis',
                    prescription: 'Throat lozenges, Gargling with warm water'
                },
                {
                    date: '2021-09-01',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }
            ]
        },
        {
            id: 8,
            name: 'Ajoa Davis',
            photo: 'https://randomuser.me/api/portraits/women/9.jpg',
            dob: '09/10/2004',
            appointment: {
                date: '04-30  10:30pm',
                type: 'In-person',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Excessive gas',
                stage: 'consultation'
            },
            vitals: {
                bloodPressure: '120/80',
                temperature: '98.9°F',
                heartRate: '71bpm',
                weight: '122lb'
            },
            visits: [
                {
                    date: '2022-01-15',
                    reason: 'Headache',
                    diagnosis: 'Tension headache',
                    prescription: 'Acetaminophen 500mg, Rest'
                },
                {
                    date: '2021-12-05',
                    reason: 'Annual checkup',
                    diagnosis: 'Normal health',
                    prescription: ''
                }
            ]
        }
    ];



    return (
        <PatientListDashboard patients={patients} />
    );
};

export default VisitReport;

