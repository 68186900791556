import React, { useState } from "react";

const AllergyTracker = ({ patient }) => {
    const [allergyTests, setAllergyTests] = useState([]);
    const [allergyTestReports] = useState([]);
    const [newAllergyTest, setNewAllergyTest] = useState({
        patientName: "",
        allergen: "",
        reaction: "",
        severity: "",
        notes: "",
    });

    const handleChange = (event) => {
        setNewAllergyTest({
            ...newAllergyTest,
            [event.target.name]: event.target.value,
        });
    };

    const addAllergyTest = (event) => {
        event.preventDefault();
        setAllergyTests([...allergyTests, newAllergyTest]);
        setNewAllergyTest({
            patientName: "",
            allergen: "",
            reaction: "",
            severity: "",
            notes: "",
        });
    };

    return (
        <>
            <div style={{ display: "flex" }}>
                <form onSubmit={addAllergyTest}>
                    <div>
                        <input
                            type="text"
                            placeholder="Allergen"
                            id="allergen"
                            name="allergen"
                            value={newAllergyTest.allergen}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Reaction"
                            id="reaction"
                            name="reaction"
                            value={newAllergyTest.reaction}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Severity"
                            id="severity"
                            name="severity"
                            value={newAllergyTest.severity}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Notes"
                            id="notes"
                            name="notes"
                            value={newAllergyTest.notes}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit">Add</button>
                </form>
            </div>
            <div style={{
                marginTop: "5px"
            }}>
                <table>
                    <thead>
                        <tr>
                            <th>Patient Name</th>
                            <th>Allergen</th>
                            <th>Reaction</th>
                            <th>Severity</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allergyTestReports.map((allergyTestReports, index) => (
                            <tr key={index}>
                                <td>{allergyTestReports.vaccineName}</td>
                                <td>{allergyTestReports.dateAdministered}</td>
                                <td>{allergyTestReports.dosage}</td>
                                <td>{allergyTestReports.nextDueDate}</td>
                                <td>{allergyTestReports.notes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default AllergyTracker;
