import React, { useState } from "react";

const LabTestManager = ({ patient }) => {
  const [labTests, setLabTests] = useState([]);
  const [labReports] = useState([]);
  const [newLabTest, setNewLabTest] = useState({
    patientName: "",
    testName: "",
    testResult: "",
    units: "",
    referenceRange: "",
    notes: "",
  });

  const handleChange = (event) => {
    setNewLabTest({
      ...newLabTest,
      [event.target.name]: event.target.value,
    });
  };

  const addLabTest = (event) => {
    event.preventDefault();
    setLabTests([...labTests, newLabTest]);
    setNewLabTest({
      patientName: "",
      testName: "",
      testResult: "",
      units: "",
      referenceRange: "",
      notes: "",
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <form onSubmit={addLabTest}>
          <div>
            <input
              type="text"
              placeholder="Test Name"
              id="testName"
              name="testName"
              value={newLabTest.testName}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Test Result"
              id="testResult"
              name="testResult"
              value={newLabTest.testResult}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Units"
              id="units"
              name="units"
              value={newLabTest.units}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Reference Range"
              id="referenceRange"
              name="referenceRange"
              value={newLabTest.referenceRange}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Notes"
              id="notes"
              name="notes"
              value={newLabTest.notes}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Add</button>
        </form>
      </div>
      <div style={{
        marginTop: "5px"
      }}>
        <table>
          <thead>
            <tr>
              <th>Test Name</th>
              <th>Result</th>
              <th>Unit</th>
              <th>Date administered</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {labReports.map((labReports, index) => (
              <tr key={index}>
                <td>{labReports.testName}</td>
                <td>{labReports.testResult}</td>
                <td>{labReports.units}</td>
                <td>{labReports.referenceRange}</td>
                <td>{labReports.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LabTestManager;

