import React from 'react';
import {
    createContext,
    useState,
    useEffect
} from "react";
import rest_api from '../api/boafo';
import { useAuth0 } from "@auth0/auth0-react";

// create context
const Context = createContext([])

function DoctorContextProvider({ children }) {
    // the value that will be given to the context
    const [profile, setProfile] = useState(false);
    const [error, setError] = useState(false);
    const { user, } = useAuth0();


    // fetch a user from a fake backend API
    useEffect(() => {
        const fetchUser = () => {
            rest_api.get(`api/v1/doctor?email=${user.email}`)
                .then(response => {
                    if (response.data.doctor[0] === null) {
                        setProfile(false);
                    } else {
                        setProfile(response.data.doctor[0]);
                    }
                })
                .catch(err => {
                    setError(err);
                    console.log(error);
                });
        };
        if (!profile) {
            fetchUser();
        }
        // eslint-disable-next-line
    }, [profile, error, user]);

    return (
        <div>
            <Context.Provider value={profile}>
                {children}
            </Context.Provider>
        </div>
    );
}

export { DoctorContextProvider, Context }