import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';

const SPCChart = ({ data }) => {
  const average = data.reduce((acc, curr) => acc + curr.value, 0) / data.length;
  const deviation = Math.sqrt(
    data.reduce((acc, curr) => acc + (curr.value - average) ** 2, 0) / data.length
  );
  const upperControlLimit = average + 3 * deviation;
  const lowerControlLimit = average - 3 * deviation;

  return (
    <div className="generic-card">
      <p>
        This chart displays data from a sample set of data, showing the distribution of the data points over time. The average of the data is {average} and the standard deviation is {deviation}.
      </p>
      <LineChart width={600} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <ReferenceLine y={upperControlLimit} stroke="red" label="Upper Control Limit" />
        <ReferenceLine y={lowerControlLimit} stroke="red" label="Lower Control Limit" />
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
      </LineChart>
      {data.some(point => point.value > upperControlLimit || point.value < lowerControlLimit) ? (
        <p style={{ color: 'red' }}>
          Warning: One or more data points are outside of the control limits. This could indicate that something is about to happen.
        </p>
      ) : (
        <p style={{ color: 'green' }}>Everything is okay! All data points are within the control limits.</p>
      )}
    </div>
  );
};

export default SPCChart;
