import React, { useState, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import MaterialReactTable from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Send } from '@mui/icons-material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useAuth0 } from "@auth0/auth0-react";

import rest_api from '../api/boafo';


const Case = () => {
	const columns = useMemo(
		() => [{
			header: 'Case Name',
			id: 'case_name',
		},
		{
			header: 'Case Description',
			id: 'case_description',
		},
		], [],
	);

	const [case_obj, set_case_obj] = useState([null]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const [update_data, set_update_data] = useState([]);

	const case_data_array = useMemo(() => case_obj.map(item => {
		const cases = (item);
		return cases
	}), [case_obj])

	const catchAllCases = () => {
		rest_api.get(`api/v1/case?`)
			.then(response => {
				if (response.status === 200 && response != null) {
					setIsLoaded(true);
					set_case_obj(response.data.case);
				} else {
					setError(error)
				}
			})
			.catch(error => {
				setError(error)
			})
	};

	// Function for creating a edit case
	const handlePushCase = async (row) => {

		const tempData = update_data;
		tempData.push({
			_id: row.row.original._id,
			case_name: row.row._valuesCache.case_name,
			case_description: row.row._valuesCache.case_description
		});
		set_update_data(update_data);
		try {
			rest_api.put('api/v1/case', update_data[0])
			// redirecting to case dashboard
		} catch (error) {
			// Handle Error Here
			console.error(error);
		}

	}

	useEffect(() => {
		catchAllCases()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { isAuthenticated, isLoading } = useAuth0();

	if (isLoading) {
		return <div className="container">Loading ...</div>;
	}

	if (error) {
		return (
			<>
				<CssBaseline />
				<Container maxwidthlg='true' style={{ marginTop: '1vh' }}>
					<Box sx={{ bgcolor: '#F5F5F5', height: '90vh' }} >
						<center>
							<div className="error" style={{ color: "red" }}>
								<p>Error message: {error.message}</p>
							</div>
						</center>
					</Box>
				</Container>
			</>
		);

	} else if (!isLoaded) {
		return (
			<>
				<CssBaseline />
				<Container maxwidthlg='true' style={{ marginTop: '1vh' }}>
					<Box sx={{ bgcolor: '#F5F5F5', height: '90vh' }} >
						<p>Loading...</p>
					</Box>
				</Container>
			</>
		);
	} else {
		return (
			isAuthenticated && (
				<>
					<CssBaseline />
					<Container maxwidthlg='true' style={{ marginTop: '1vh' }}>
						<Box sx={{ bgcolor: '#F5F5F5', height: '90vh', color: '#000080' }} >
							<Stack spacing={2} direction="row">
								<MaterialReactTable
									columns={columns}
									data={case_data_array}
									enableEditing
									enableRowVirtualization
									onEditRowSubmit={handlePushCase}
									muiTableContainerProps={{ sx: { maxHeight: '78vh' } }}
									virtualizerProps={{ overscan: 20 }}
									positionToolbarAlertBanner="bottom"
									enableColumnOrdering
									enableColumnResizing
									renderRowActionMenuItems={({ closeMenu }) => [

										<MenuItem
											key={0}
											onClick={() => {
												// Send email logic...
												closeMenu();
											}}
											sx={{ m: 0 }}
										>
											<ListItemIcon>
												<Send />
											</ListItemIcon>
											Flag
										</MenuItem>,

									]}
								/>

								<NavLink end to='/case/create'>
									<Button variant="contained" color="success" type='submit'> Add</Button>
								</NavLink>
							</Stack>
						</Box>
					</Container>

				</>

			)
		)

	}
}

export default Case