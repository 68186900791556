import React, { useState } from 'react';

const ConsentForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [hasConsented, setHasConsented] = useState(false);
  const [signedDate, setSignedDate] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleConsentChange = (event) => {
    setHasConsented(event.target.checked);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (hasConsented) {
      // Save the form data to a PDF file
      const formData = { name, email, signedDate };
      // Code to convert the form data to a PDF file
      console.log('Form data:', formData);
    } else {
      alert('Please read and accept the privacy policy before submitting the form.');
    }
  };

  const handleSignature = (event) => {
    event.preventDefault();
    setSignedDate(new Date().toLocaleDateString());
  };

  return (
    <form onSubmit={handleFormSubmit} className="form-card">
      <h3>Privacy Policy</h3>
      <div>
        <p>Boafo Health is committed to protecting the privacy and security of our customers' personal information. We collect, store, and process personal data only for specific and legitimate purposes, and we use appropriate technical and organizational measures to ensure the confidentiality, integrity, and availability of the data. (…)</p>
      </div>
      <input type="text" placeholder="Name" value={name} onChange={handleNameChange} />
      <br />
      <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
      <br />
      <input type="checkbox" checked={hasConsented} onChange={handleConsentChange} />
      <label>
        I have read and agree to the <a href="#privacy-policy">privacy policy</a>.
      </label>
      <br />
      <button type="submit">Save as PDF</button>
      <button onClick={handleSignature}>Sign</button>
      {signedDate && <p>Signed on: {signedDate}</p>}
      <hr />
    </form>
  );
};

export default ConsentForm;
