import React, { useState, useEffect, useMemo } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import MaterialReactTable from 'material-react-table';

import rest_api from '../api/boafo';


const Prescription = () => {
	const columns = useMemo(
		() => [{
			header: 'Medicine ID',
			id: 'medicine_id',
		},
		{
			header: 'Prescribed Date',
			id: 'date_prescribed',
		},
		{
			header: 'Prescribed Qty',
			id: 'quantity',
		},
		{
			header: 'Daily Intake Qty',
			id: 'daily_intake_amount',
		},
		{
			header: 'Prescribed By',
			id: 'prescription_by',
		},
		{
			header: 'Medication Status',
			id: 'medication_status',
		},
		], [],
	);

	const [prescribe_obj, set_prescribe_obj] = useState([null]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const prescribe_data_array = useMemo(() => prescribe_obj.map(item => {
		const prescribes = (item);
		return prescribes
	}), [prescribe_obj])

	const catchAllPrescriptions = () => {
		rest_api.get(`api/v1/prescription?`)
			.then(response => {
				if (response.status === 200 && response != null) {
					setIsLoaded(true);
					set_prescribe_obj(response.data.prescription);
				} else {
					setError(error)
				}
			})
			.catch(error => {
				setError(error)
			})
	};

	useEffect(() => {
		catchAllPrescriptions()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (error) {
		return (
			<div className="container">
				<center>
					<div className="error" style={{ color: "red" }}>
						<p>Error message: {error.message}</p>
					</div>
				</center>
			</div>
		);

	} else if (!isLoaded) {
		return (
			<div className="container">
				<center>
					<div className="loading" style={{ color: "#F58634" }}>
						<p>Loading...</p>
					</div>
				</center>
			</div>
		);
	} else {
		return (
			<>
				<div className="container">
					<MaterialReactTable
						columns={columns}
						data={prescribe_data_array}
						enableRowVirtualization
						muiTableContainerProps={{ sx: { maxHeight: '720px', maxWidth: '1300px' } }}
						virtualizerProps={{ overscan: 20 }}
						positionToolbarAlertBanner="bottom"
						enableColumnOrdering
						enableColumnResizing

					/>
				</div>

			</>

		)
	}
}

export default Prescription