import React, { useState } from "react";

const ClerksManager = () => {
  const [clerks, setClerks] = useState([]);
  const [currentClerk, setCurrentClerk] = useState({
    id: null,
    name: "",
    role: "",
  });

  const handleInputChange = (event) => {
    setCurrentClerk({ ...currentClerk, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!currentClerk.name || !currentClerk.role) return;

    if (currentClerk.id === null) {
      const newClerk = { ...currentClerk, id: clerks.length + 1 };
      setClerks([...clerks, newClerk]);
    } else {
      const index = clerks.findIndex((clerk) => clerk.id === currentClerk.id);
      const updatedClerks = [...clerks];
      updatedClerks[index] = currentClerk;
      setClerks(updatedClerks);
    }

    setCurrentClerk({ id: null, name: "", role: "" });
  };

  const handleEdit = (clerk) => {
    setCurrentClerk(clerk);
  };

  const handleDelete = (id) => {
    setClerks(clerks.filter((clerk) => clerk.id !== id));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={currentClerk.name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Role"
            name="role"
            value={currentClerk.role}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <button type="submit">Save</button>
        </div>
      </form>
      <br />
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clerks.map((clerk) => (
            <tr key={clerk.id}>
              <td>{clerk.id}</td>
              <td>{clerk.name}</td>
              <td>{clerk.role}</td>
              <td>
                <button onClick={() => handleEdit(clerk)}>Edit</button>
                <button onClick={() => handleDelete(clerk.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ClerksManager
