import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
	MDBInput,
	MDBValidation,
	MDBValidationItem,
} from 'mdb-react-ui-kit';

import rest_api from '../api/boafo';


function Create() {

	// Making usestate for setting and
	// fetching a value in jsx

	const [formValue, setFormValue] = useState({
		medicine_name: '',
		purpose: '',
		initial_quantity: '',
		stock_quantity: '',
		date_delivered: ''
	});

	const onChange = (e: any) => {
		setFormValue({ ...formValue, [e.target.name]: e.target.value });
	};

	// Using useNavigation for redirecting to pages
	let history = useNavigate();

	// Function for creating a post medicine
	const handelSubmit = async (event) => {
		event.preventDefault();
		if (formValue.medicine_name.length > 0 &&
			formValue.purpose.length > 0 &&
			formValue.initial_quantity.length > 0 &&
			formValue.stock_quantity.length > 0 &&
			formValue.date_delivered.length > 0
		) {
			await rest_api.post('api/v1/medicine', formValue)
			// redirecting to medicine dashboard
			history('/medicine')
		}
		else {
			alert("Missing required information")
		}
	}

	return (
		<div className="container">

			<MDBValidation className='row g-3' isValidated onSubmit={handelSubmit}>
				<MDBValidationItem feedback='Please enter a Medicine Name.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.medicine_name}
						type='text'
						className='form-control'
						name='medicine_name'
						onChange={onChange}
						id='validationMedicineName'
						required
						placeholder='medicine Name'
					/>
				</MDBValidationItem>
				<MDBValidationItem feedback='Please enter a Purpose.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.purpose}
						type='textarea'
						className='form-control'
						name='purpose'
						onChange={onChange}
						id='validationPurpose'
						required
						placeholder='Purpose'
					/>
				</MDBValidationItem>
				<MDBValidationItem feedback='Please enter Initial Quantity.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.initial_quantity}
						type='text'
						className='form-control'
						name='initial_quantity'
						onChange={onChange}
						id='validationInitialQuantity'
						required
						placeholder='Initial Quantity'
					/>
				</MDBValidationItem>
				<MDBValidationItem feedback='Please enter Stock Quantity.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.stock_quantity}
						type='textarea'
						className='form-control'
						name='stock_quantity'
						onChange={onChange}
						id='validationStockQuantity'
						required
						placeholder='Stock Quantity'
					/>
				</MDBValidationItem>
				<MDBValidationItem feedback='Please enter Date Delivered.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.date_delivered}
						type='textarea'
						className='form-control'
						name='date_delivered'
						onChange={onChange}
						id='validationDateDelivered'
						required
						placeholder='Date Delivered'
					/>
				</MDBValidationItem>

				<Stack spacing={2} direction="row">
					<Button variant="contained" color="success" type='submit'>Submit</Button>

					<NavLink end to='/medicine' >
						<Button variant="contained" color="primary" type='submit'>Cancel</Button>
					</NavLink>
				</Stack>
			</MDBValidation>

		</div>
	)
}

export default Create
