import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
	MDBInput,
	MDBValidation,
	MDBValidationItem,
	MDBTextArea
} from 'mdb-react-ui-kit';

import rest_api from '../api/boafo';


function Create() {

	// Making usestate for setting and
	// fetching a value in jsx

	const [formValue, setFormValue] = useState({
		doctor_name: '',
		doctor_description: '',
	});

	const onChange = (e: any) => {
		setFormValue({ ...formValue, [e.target.name]: e.target.value });
	};

	// Using useNavigation for redirecting to pages
	let history = useNavigate();

	// Function for creating a post doctor
	const handelSubmit = async (event) => {
		event.preventDefault();
		if (formValue.doctor_name.length > 0 && formValue.doctor_description.length > 0) {
			await rest_api.post('api/v1/doctor', formValue)
			// redirecting to doctor dashboard
			history('/doctor')
		}
		else {
			alert("Missing required information")
		}
	}

	return (
		<div className="container">

			<MDBValidation className='row g-3' isValidated onSubmit={handelSubmit}>
				<MDBValidationItem feedback='Please enter a Doctor Name.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.doctor_name}
						type='text'
						className='form-control'
						name='doctor_name'
						onChange={onChange}
						id='validationDoctorName'
						required
						placeholder='doctor Name'
					/>
				</MDBValidationItem>
				<MDBValidationItem feedback='Please enter a Doctor Description.' invalid className='mb-3 pb-1'>
					<MDBTextArea
						value={formValue.doctor_description}
						type='textarea'
						className='form-control'
						name='doctor_description'
						onChange={onChange}
						id='validationDoctorDescription'
						required
						placeholder='Doctor Description'
					/>
				</MDBValidationItem>

				<Stack spacing={2} direction="row">
					<Button variant="contained" color="success" type='submit'>Submit</Button>

					<NavLink end to='/doctor' >
						<Button variant="contained" color="primary" type='submit'>Cancel</Button>
					</NavLink>
				</Stack>
			</MDBValidation>

		</div>
	)
}

export default Create
