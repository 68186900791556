import React, { useState } from 'react';

const DoctorVisitScheduling = () => {
    const [doNothing, setDoNothing] = useState()
    const [data] = useState([
        {
            doctor: 'Dr. Smith',
            patient: 'John Doe',
            time: '9:00 AM',
            date: '2/22/25',
            appointmentDetails: 'Follow up for flu symptoms',
        },
        {
            doctor: 'Dr. Johnson',
            patient: 'Jane Doe',
            time: '10:00 AM',
            date: '2/22/25',
            appointmentDetails: 'Physical examination',
        },
        {
            doctor: 'Dr. Brown',
            patient: 'Jack Smith',
            time: '11:00 AM',
            date: '2/22/25',
            appointmentDetails: 'Consultation for back pain',
        },
    ]);

    const handleClick = () => {
        setDoNothing(!doNothing);
    };

    return (
        <table>
            <thead>
                <tr>
                    <th>Doctor</th>
                    <th>Patient</th>
                    <th>Time</th>
                    <th>Date</th>
                    <th>Appointment Details</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {data.map(({ doctor, patient, time, date, appointmentDetails }) => (
                    <tr key={doctor}>
                        <td>{doctor}</td>
                        <td>{patient}</td>
                        <td>{time}</td>
                        <td>{date}</td>
                        <td>{appointmentDetails}</td>
                        <td>
                            <button onClick={() => handleClick()}>Complete</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DoctorVisitScheduling;
