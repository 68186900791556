import React from 'react';
import { useState } from 'react';
import List from "@mui/material/List"
import Container from '@mui/material/Container';
import Collapse from '@mui/material/Collapse';
import { useAuth0 } from "@auth0/auth0-react";
import ClinicalVisitsAnalytics from '../Report/PatientVisitsTable';
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { TrendingUp } from "@mui/icons-material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Anova from "./Anova"
import { TrendingFlat } from "@mui/icons-material"
import { LinearScale } from "@mui/icons-material"
import { BubbleChart } from "@mui/icons-material"
import TStatistic from './TStatistic';
import SPCChart from './SPCChart';


const Report = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const [openReport, setOpenReport] = useState(false);
    const [openAnova, setopenANova] = useState(false);
    const [openSpc, setopenSpc] = useState(false);
    const [openTStatistic, setopenTStatistic] = useState(false);

    const AnonaData = [
        { group: 'Group 1', value: 1.2 },
        { group: 'Group 1', value: 0.1 },
        { group: 'Group 1', value: 1.2 },
        { group: 'Group 2', value: 1.2 },
        { group: 'Group 2', value: 0.2 },
        { group: 'Group 2', value: 1.1 },
        { group: 'Group 3', value: 1.2 },
        { group: 'Group 3', value: 0.1 },
        { group: 'Group 3', value: 0.2 },
    ];

    const TStatisticData = [
        { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
        { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
        { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
        { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
        { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
        { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
        { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
    ];

    const SpcData = [
        { name: '1', value: 40 },
        { name: '2', value: 30 },
        { name: '3', value: 50 },
        { name: '4', value: 45 },
        { name: '5', value: 35 },
        { name: '6', value: 55 },
        { name: '7', value: 47 },
        { name: '8', value: 98 },
        { name: '9', value: 2 },
        { name: '10', value: 2 },
    ];


    if (isLoading) {
        return (
            <>
                <Container maxwidthlg='true' style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                    padding: '40px',
                    backgroundColor: '#F8F8F8',
                }}>
                    <p>Loading...</p>
                </Container>
            </>
        )
    }

    const handleVisitClick = () => {
        setOpenReport(!openReport);
    };

    const handleAnovaClick = () => {
        setopenANova(!openAnova);
    };

    const handleSpcClick = () => {
        setopenSpc(!openSpc);
    };

    const handleTStatisticClick = () => {
        setopenTStatistic(!openTStatistic);
    };
    return (
        isAuthenticated && (
            <>
                <Container maxwidthlg='true' style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                    padding: '10px',
                    backgroundColor: '#F8F8F8',
                }}>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItem button dense onClick={handleAnovaClick}
                            sx={{
                                bgcolor: '#dce2e3',
                                border: '0.5px solid #343a40',
                                borderRadius: '2%',
                                color: 'black',
                                fontWeight: '600'
                            }}>
                            <ListItemIcon sx={{ color: '#orange' }}>
                                <BubbleChart />
                            </ListItemIcon>
                            <ListItemText primary="ANOVA" />
                            {/*code to openAnova and closed list*/}
                            {openAnova ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openAnova} timeout="auto" unmountOnExit>
                            <List>
                                <Anova data={AnonaData} />
                            </List>
                        </Collapse>
                    </List>
                    <List
                        disablePadding
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItem button dense onClick={handleTStatisticClick}
                            sx={{
                                bgcolor: '#dce2e3',
                                border: '0.5px solid #343a40',
                                borderRadius: '2%',
                                color: 'black',
                                fontWeight: '600'
                            }}>
                            <ListItemIcon sx={{ color: 'green' }}>
                                <TrendingFlat />
                            </ListItemIcon>
                            <ListItemText primary="T-Statistic" />
                            {/*code to openTStatistic and closed list*/}
                            {openTStatistic ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openTStatistic} timeout="auto" unmountOnExit>
                            <List>
                                <TStatistic data={TStatisticData} />
                            </List>
                        </Collapse>
                    </List>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItem button dense onClick={handleSpcClick}
                            sx={{
                                bgcolor: '#dce2e3',
                                border: '0.5px solid #343a40',
                                borderRadius: '2%',
                                color: 'black',
                                fontWeight: '600'
                            }}>
                            <ListItemIcon sx={{ color: 'red' }}>
                                <LinearScale />
                            </ListItemIcon>
                            <ListItemText primary="SPC" />
                            {/*code to openSpc and closed list*/}
                            {openSpc ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openSpc} timeout="auto" unmountOnExit>
                            <List>
                                <SPCChart data={SpcData} />
                            </List>
                        </Collapse>
                    </List>
                </Container>
            </>
        )
    )
}

export default Report