import React, { useState, useEffect, useMemo } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import MaterialReactTable from 'material-react-table';

import rest_api from '../api/boafo';


const Vaccine = () => {
	const columns = useMemo(
		() => [{
			header: 'Vaccine Name',
			id: 'vaccine_name',
		},
		{
			header: 'Inoculation Date',
			id: 'inoculation_date',
		},
		{
			header: 'Inoculated by',
			id: 'inoculated_by',
		},
		], [],
	);

	const [vaccine_obj, set_vaccine_obj] = useState([null]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const vaccine_data_array = useMemo(() => vaccine_obj.map(item => {
		const vaccines = (item);
		return vaccines
	}), [vaccine_obj])

	const catchAllVaccines = () => {
		rest_api.get(`api/v1/vaccine?`)
			.then(response => {
				if (response.status === 200 && response != null) {
					setIsLoaded(true);
					set_vaccine_obj(response.data.vaccine);
				} else {
					setError(error)
				}
			})
			.catch(error => {
				setError(error)
			})
	};

	useEffect(() => {
		catchAllVaccines()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (error) {
		return (
			<div className="container">
				<center>
					<div className="error" style={{ color: "red" }}>
						<p>Error message: {error.message}</p>
					</div>
				</center>
			</div>
		);

	} else if (!isLoaded) {
		return (
			<div className="container">
				<center>
					<div className="loading" style={{ color: "#F58634" }}>
						<p>Loading...</p>
					</div>
				</center>
			</div>
		);
	} else {
		return (
			<>
				<div className="container">
					<MaterialReactTable
						columns={columns}
						data={vaccine_data_array}
						enableRowVirtualization
						muiTableContainerProps={{ sx: { maxHeight: '720px', maxWidth: '1200px' } }}
						virtualizerProps={{ overscan: 20 }}
						positionToolbarAlertBanner="bottom"
						enableColumnOrdering
						enableColumnResizing

					/>
				</div>

			</>

		)
	}
}

export default Vaccine