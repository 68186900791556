import React, { useState } from "react";

const PrescriptionManager = ({ patient }) => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescriptionReports] = useState([]);
  const [newPrescription, setNewPrescription] = useState({
    patientName: "",
    drugName: "",
    dosage: "",
    frequency: "",
    duration: "",
    notes: "",
  });

  const handleChange = (event) => {
    setNewPrescription({
      ...newPrescription,
      [event.target.name]: event.target.value,
    });
  };

  const addPrescription = (event) => {
    event.preventDefault();
    setPrescriptions([...prescriptions, newPrescription]);
    setNewPrescription({
      patientName: "",
      drugName: "",
      dosage: "",
      frequency: "",
      duration: "",
      notes: "",
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <form onSubmit={addPrescription}>
          <div>
            <input
              type="text"
              placeholder="Drug Name"
              id="drugName"
              name="drugName"
              value={newPrescription.drugName}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Dosage"
              id="dosage"
              name="dosage"
              value={newPrescription.dosage}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Frequency"
              id="frequency"
              name="frequency"
              value={newPrescription.frequency}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Duration"
              id="duration"
              name="duration"
              value={newPrescription.duration}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Notes"
              id="notes"
              name="notes"
              value={newPrescription.notes}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Add </button>
        </form>
      </div>
      <div style={{
        marginTop: "5px"
      }}>
        <table>
          <thead>
            <tr>
              <th>Drug Name</th>
              <th>Dosage</th>
              <th>Last Prescribed</th>
              <th>Duration</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {prescriptionReports.map((prescriptionReports, index) => (
              <tr key={index}>
                <td>{prescriptionReports.drugName}</td>
                <td>{prescriptionReports.dosage}</td>
                <td>{prescriptionReports.frequency}</td>
                <td>{prescriptionReports.duration}</td>
                <td>{prescriptionReports.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PrescriptionManager;
