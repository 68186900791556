import React, { useState } from 'react';
import { Icon } from 'react-icons-kit';
import { heart } from 'react-icons-kit/ionicons/heart';
import { thermometer } from 'react-icons-kit/ionicons/thermometer';
import { iosPulse } from 'react-icons-kit/ionicons/iosPulse';
import { plusSquare } from 'react-icons-kit/fa/plusSquare';
import { windowClose } from 'react-icons-kit/fa/windowClose';
import { scale } from 'react-icons-kit/metrize/scale'
import { clock } from 'react-icons-kit/oct/clock'
import { ic_room } from 'react-icons-kit/md/ic_room'
import { ic_medical_services } from 'react-icons-kit/md/ic_medical_services'
import { calendar } from 'react-icons-kit/fa/calendar'
import { iosMedicalOutline } from 'react-icons-kit/ionicons/iosMedicalOutline'

const PatientCard = ({ patient, isSelected, handlePatientSelect }) => {
  const { id, name, photo, vitals, appointment } = patient;
  const { bloodPressure, temperature, heartRate, weight } = vitals;
  const { date, type, doctor, status, reason, stage } = appointment;

  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleAppointmentAction = (action) => {
    // Handle appointment actions (reject, confirm, etc.) here
    console.log(`Appointment action: ${action}`);
  };

  const handleClick = () => {
    if (handlePatientSelect) {
      handlePatientSelect(id);
    }
  };


  return (
    <div>
      <div onClick={handleClick} >
        <div className={`patient-card ${isSelected ? 'active-patient-card' : ''}`}>
          <img className="patient-card-image" src={photo} alt={name} />
          <div className="patient-card-info">
            <div className="patient-card-name">
              <h6>{name}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className={`patient-card-vitals ${isSelected ? 'active-patient-card-vitals' : ''}`}>
        {appointment.stage === 'consultation' ?
          <div className="patient-card-vital-label">
            <table style={{ textAlign: 'left', paddingLeft: '20px' }}>
              <tbody>
                <tr> <Icon style={{ color: '#f5427b', paddingRight: '5px' }} size={20} icon={ic_medical_services} /> Reason:  <b>{reason}</b></tr>
                <tr><Icon style={{ color: '#87b0c9', paddingRight: '5px' }} size={20} icon={iosPulse} />  BP:  <b>{bloodPressure}</b></tr>
                <tr> <Icon style={{ color: '#a1cfca', paddingRight: '5px' }} size={20} icon={thermometer} />  Temp:  <b>{temperature}</b></tr>
                <tr> <Icon style={{ color: '#fadfc5', paddingRight: '5px' }} size={20} icon={heart} /> Heart rate:  <b>{heartRate}</b></tr>
                <tr> <Icon style={{ color: '#243dab', paddingRight: '5px' }} size={20} icon={scale} /> Weight:  <b>{weight}</b></tr>
                <tr> <Icon style={{ color: '#53b03c', paddingRight: '5px' }} size={20} icon={ic_room} /> Room:   <b>2</b></tr>
                <tr> <Icon style={{ color: '#3c61b0', paddingRight: '5px' }} size={20} icon={clock} /> Wait time:  <b>15m</b></tr>
              </tbody>
            </table>
          </div>
          : ''}
        {appointment.stage === 'apt' ?
          <div >
            <div style={{ textAlign: 'center' }}>
              <Icon className="clickable-vital-icon" onClick={() => handleAppointmentAction('Reject')}
                style={{
                  color: '#f7816f',
                  marginRight: '4px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  borderRadius: '4%',
                  marginBottom: "5px"
                }}
                size={20} icon={windowClose} />
              <Icon className="clickable-vital-icon" onClick={() => handleAppointmentAction('Accept')}
                style={{
                  color: '#87c989',
                  marginLeft: '4px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  borderRadius: '4%',
                  marginBottom: "5px"
                }}
                size={20} icon={plusSquare} />
            </div >
            <div className="patient-card-vital-label">
              <table style={{ textAlign: 'left', paddingLeft: '20px' }}>
                <tbody>
                  <tr> <Icon style={{ color: '#fac5da', paddingRight: '5px' }} size={20} icon={calendar} />  Apt. time:  <b>{date}</b></tr>
                  <tr> <Icon style={{ color: '#3cb051', paddingRight: '5px' }} size={20} icon={iosMedicalOutline} /> Apt. type  <b>{type}</b></tr>
                  <tr> <Icon style={{ color: '#f5427b', paddingRight: '5px' }} size={20} icon={ic_medical_services} /> Reason: <b>{reason}</b></tr>
                </tbody>
              </table>
            </div>
          </div>
          : ''}
      </div >
    </div>
  )
}

export default PatientCard;