import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
	MDBInput,
	MDBValidation,
	MDBValidationItem,
	MDBTextArea
} from 'mdb-react-ui-kit';

import rest_api from '../api/boafo';


function Create() {

	// Making usestate for setting and
	// fetching a value in jsx

	const [formValue, setFormValue] = useState({
		case_name: '',
		case_description: '',
	});

	const onChange = (e: any) => {
		setFormValue({ ...formValue, [e.target.name]: e.target.value });
	};

	// Using useNavigation for redirecting to pages
	let history = useNavigate();

	// Function for creating a post case
	const handelSubmit = async (event) => {
		event.preventDefault();
		if (formValue.case_name.length > 0 && formValue.case_description.length > 0) {
			await rest_api.post('api/v1/case', formValue)
			// redirecting to case dashboard
			history('/case')
		}
		else {
			alert("Missing required information")
		}
	}

	return (
		<div className="container">

			<MDBValidation className='row g-3' isValidated onSubmit={handelSubmit}>
				<MDBValidationItem feedback='Please enter a Case Name.' invalid className='mb-3 pb-1'>
					<MDBInput
						value={formValue.case_name}
						type='text'
						className='form-control'
						name='case_name'
						onChange={onChange}
						id='validationCaseName'
						required
						placeholder='case Name'
					/>
				</MDBValidationItem>
				<MDBValidationItem feedback='Please enter a Case Description.' invalid className='mb-3 pb-1'>
					<MDBTextArea
						value={formValue.case_description}
						type='textarea'
						className='form-control'
						name='case_description'
						onChange={onChange}
						id='validationCaseDescription'
						required
						placeholder='Case Description'
					/>
				</MDBValidationItem>

				<Stack spacing={2} direction="row">
					<Button variant="contained" color="success" type='submit'>Submit</Button>

					<NavLink end to='/case' >
						<Button variant="contained" color="primary" type='submit'>Cancel</Button>
					</NavLink>
				</Stack>
			</MDBValidation>

		</div>
	)
}

export default Create
