import React, { useState, useEffect, useMemo } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import MaterialReactTable from 'material-react-table';

import rest_api from '../api/boafo';


const Allergy = () => {
	const columns = useMemo(
		() => [{
			header: 'Allergy Code',
			id: 'allergy_code',
		},
		{
			header: 'Allergy Name',
			id: 'allergy_name',
		},
		{
			header: 'Description',
			id: 'allergy_description',
		},
		{
			header: 'Status',
			id: 'allergy_status',
		}
		], [],
	);

	const [allergy_obj, set_allergy_obj] = useState([null]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const allergy_data_array = useMemo(() => allergy_obj.map(item => {
		const allergys = (item);
		return allergys
	}), [allergy_obj])

	const catchAllAllergys = () => {
		rest_api.get(`api/v1/allergy?`)
			.then(response => {
				if (response.status === 200 && response != null) {
					setIsLoaded(true);
					set_allergy_obj(response.data.allergy);
				} else {
					setError(error)
				}
			})
			.catch(error => {
				setError(error)
			})
	};

	useEffect(() => {
		catchAllAllergys()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (error) {
		return (
			<div className="container">
				<center>
					<div className="error" style={{ color: "red" }}>
						<p>Error message: {error.message}</p>
					</div>
				</center>
			</div>
		);

	} else if (!isLoaded) {
		return (
			<div className="container">
				<center>
					<div className="loading" style={{ color: "#F58634" }}>
						<p>Loading...</p>
					</div>
				</center>
			</div>
		);
	} else {
		return (
			<>
				<div className="container">
					<MaterialReactTable
						columns={columns}
						data={allergy_data_array}
						enableRowVirtualization
						virtualizerProps={{ overscan: 20 }}
						positionToolbarAlertBanner="bottom"
						enableColumnOrdering
						enableColumnResizing

					/>
				</div>

			</>

		)
	}
}

export default Allergy