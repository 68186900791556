import React, { useState } from 'react';

const VaccinationTracker = ({ patient }) => {
    const [vaccinations, setVaccinations] = useState([]);
    const [vaccinationReports] = useState([]);
    const [newVaccination, setNewVaccination] = useState({
        patientName: '',
        vaccineName: '',
        dateAdministered: '',
        dosage: '',
        nextDueDate: '',
        administrationRoute: '',
        sideEffects: '',
        notes: ''
    });

    const handleChange = event => {
        setNewVaccination({
            ...newVaccination,
            [event.target.name]: event.target.value
        });
    };

    const addVaccination = event => {
        event.preventDefault();
        setVaccinations([...vaccinations, newVaccination]);
        setNewVaccination({
            patientName: '',
            vaccineName: '',
            dateAdministered: '',
            dosage: '',
            nextDueDate: '',
            administrationRoute: '',
            sideEffects: '',
            notes: ''
        });
    };

    return (
        <>
            <div style={{ display: "flex" }}>
                <form onSubmit={addVaccination}>
                    <div>
                        <input
                            type="text"
                            placeholder="Vaccine Name"
                            id="vaccineName"
                            name="vaccineName"
                            value={newVaccination.vaccineName}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Date Administered"
                            id="dateAdministered"
                            name="dateAdministered"
                            value={newVaccination.dateAdministered}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Dosage"
                            id="dosage"
                            name="dosage"
                            value={newVaccination.dosage}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Next Due Date"
                            id="nextDueDate"
                            name="nextDueDate"
                            value={newVaccination.nextDueDate}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Administration Route"
                            id="administrationRoute"
                            name="administrationRoute"
                            value={newVaccination.administrationRoute}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Side Effects"
                            id="sideEffects"
                            name="sideEffects"
                            value={newVaccination.sideEffects}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Notes"
                            id="notes"
                            name="notes"
                            value={newVaccination.notes}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button type="submit">Add</button>
                    </div>
                </form>
            </div>
            <div style={{
                marginTop: "5px"
            }}>
                <table>
                    <thead>
                        <tr>
                            <th>Vaccination Name</th>
                            <th>Date Administered</th>
                            <th>Dosage</th>
                            <th>Next Due Date</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vaccinationReports.map((vaccinationReports, index) => (
                            <tr key={index}>
                                <td>{vaccinationReports.vaccineName}</td>
                                <td>{vaccinationReports.dateAdministered}</td>
                                <td>{vaccinationReports.dosage}</td>
                                <td>{vaccinationReports.nextDueDate}</td>
                                <td>{vaccinationReports.notes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default VaccinationTracker;


