import React, { useState, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import MaterialReactTable from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Send } from '@mui/icons-material';

import rest_api from '../api/boafo';


const Medicine = () => {
	const columns = useMemo(
		() => [{
			header: 'Medicine Name',
			id: 'medicine_name',
		},
		{
			header: 'Purpose',
			id: 'purpose',
		},
		{
			header: 'Initial Qty',
			id: 'initial_quantity',
		},
		{
			header: 'Stock Qty',
			id: 'stock_quantity',
		},
		{
			header: 'Delivered on',
			id: 'date_delivered',
		}
		], [],
	);

	const [medicine_obj, set_medicine_obj] = useState([null]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const [update_data, set_update_data] = useState([]);

	const medicine_data_array = useMemo(() => medicine_obj.map(item => {
		const medicines = (item);
		return medicines
	}), [medicine_obj])

	const catchAllMedicines = () => {
		rest_api.get(`api/v1/medicine?`)
			.then(response => {
				if (response.status === 200 && response != null) {
					setIsLoaded(true);
					set_medicine_obj(response.data.medicine);
				} else {
					setError(error)
				}
			})
			.catch(error => {
				setError(error)
			})
	};

	// Function for creating a edit medicine
	const handlePushMedicine = async (row) => {

		const tempData = update_data;
		tempData.push({
			_id: row.row.original._id,
			medicine_name: row.row._valuesCache.medicine_name,
			purpose: row.row._valuesCache.purpose,
			initial_quantity: row.row._valuesCache.initial_quantity,
			stock_quantity: row.row._valuesCache.stock_quantity,
			date_delivered: row.row._valuesCache.date_delivered
		});
		set_update_data(update_data);
		try {
			rest_api.put('api/v1/medicine', update_data[0])
			// redirecting to medicine dashboard
		} catch (error) {
			// Handle Error Here
			console.error(error);
		}

	}

	useEffect(() => {
		catchAllMedicines()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (error) {
		return (
			<div className="container">
				<center>
					<div className="error" style={{ color: "red" }}>
						<p>Error message: {error.message}</p>
					</div>
				</center>
			</div>
		);

	} else if (!isLoaded) {
		return (
			<div className="container">
				<center>
					<div className="loading" style={{ color: "#F58634" }}>
						<p>Loading...</p>
					</div>
				</center>
			</div>
		);
	} else {
		return (
			<>
				<div className="container">
					<Stack spacing={2} direction="row">
						<MaterialReactTable
							columns={columns}
							data={medicine_data_array}
							enableEditing
							enableRowVirtualization
							onEditRowSubmit={handlePushMedicine}
							muiTableContainerProps={{ sx: { maxHeight: '720px', maxWidth: '1200px' } }}
							virtualizerProps={{ overscan: 20 }}
							positionToolbarAlertBanner="bottom"
							enableColumnOrdering
							enableColumnResizing
							renderRowActionMenuItems={({ closeMenu }) => [

								<MenuItem
									key={0}
									onClick={() => {
										// Send email logic...
										closeMenu();
									}}
									sx={{ m: 0 }}
								>
									<ListItemIcon>
										<Send />
									</ListItemIcon>
									Flag
								</MenuItem>,

							]}
						/>

						<NavLink end to='/medicine/create'>
							<Button variant="contained" color="success" type='submit'> Add</Button>
						</NavLink>
					</Stack>
				</div>

			</>

		)
	}
}

export default Medicine