import React, { useState } from "react";
import PatientCard from "./PatientCard";
import GPT3Response from '../Common/Chatgpt'
import Consultation from '../Consultation'
// import Report from '../Report/'
import PatientVisitsTable from '../Report/PatientVisitsTable'
import Container from '@mui/material/Container';
import AdmitDischargeForm from "./AdmitDischargeForm";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Mousewheel, Pagination, Navigation } from "swiper/core";
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

SwiperCore.use([Keyboard, Mousewheel, Pagination, Navigation]);

function PatientListDashboard({ patients }) {
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [enableChatgpt] = useState(true);

    const handlePatientSelect = (patientId) => {
        setSelectedPatientId(patientId);

    };

    const selectedPatient = patients.find((patient) => patient.id === selectedPatientId);

    return (
        <>
            <div style={{ display: "flex" }}>
                <div className="patient-list">
                    <Swiper
                        slidesPerView="2"
                        effect={true}
                        loop={true}
                        mousewheel={true}
                        keyboard={{ enabled: true, onlyInViewport: false }}
                        direction="vertical"
                        pagination={{ clickable: true }}
                        height={window.innerHeight}
                        spaceBetween={150}
                        simulateTouch={true}
                    >
                        {patients.map((patient) => (
                            <SwiperSlide>
                                <PatientCard
                                    key={patient.id}
                                    patient={patient}
                                    handlePatientSelect={handlePatientSelect}
                                    isSelected={selectedPatientId === patient.id}
                                />
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </div>
                {selectedPatient ? (
                    <div className="generic-card" key={selectedPatient.id}>
                        <div style={{ color: "black" }}>
                            <h5>{selectedPatient.name}</h5>
                        </div>
                        <div style={{ fontSize: "12px" }}>
                            {selectedPatient.dob}
                        </div>
                        {enableChatgpt ? (
                            <GPT3Response
                                query={
                                    "Based on the following vital information, blood pressure, " +
                                    selectedPatient.vitals.bloodPressure +
                                    ", body temperature, " +
                                    selectedPatient.vitals.temperature +
                                    ", heart rate, " +
                                    selectedPatient.vitals.heartRate +
                                    ", and date of birth being,  " +
                                    selectedPatient.dob +
                                    " give a clinical support summary of overall health and inferrance summary" +
                                    "suggest normal ranges and how it compares with the provided information. Make it concise"
                                }
                            />
                        ) : null}
                        <br />
                        <Container maxwidthlg='true' style={{
                            marginTop: '2vh',
                            boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                            padding: '10px',
                            backgroundColor: '#F8F8F8',
                        }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Last visit</th>
                                        <th>Reason</th>
                                        <th>Diagnosis</th>
                                        <th>Prescription</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPatient.visits.map((visit) => (
                                        <tr key={visit.date}>
                                            <td>{visit.date}</td>
                                            <td>{visit.reason}</td>
                                            <td>{visit.diagnosis}</td>
                                            <td>{visit.prescription}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Container>
                        <div>
                            <Consultation
                                patient={selectedPatient}
                            />
                        </div>
                        {/* <div>
                            <Report />
                        </div> */}
                        <AdmitDischargeForm patient={selectedPatient} />
                    </div>
                ) : (
                    <div className="generic-card">
                        <PatientVisitsTable />
                    </div>
                )}
            </div>

        </>
    );
}

export default PatientListDashboard;
