import React, { useState } from 'react';
import Container from '@mui/material/Container';

import ConsentForm from './ConsentForm';


const Resource = () => {
    const [isFormSigned, setIsFormSigned] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleFormSigned = () => {
        setIsFormSigned(true);
    };

    const handleShowForm = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleCloseForm = () => {
        setIsFormVisible(false);
    };

    const similarForms = [
        { name: 'HIPAA Authorization', isSigned: true },
        { name: 'Patient Information Form', isSigned: false },
        { name: 'Consent to Treat a Minor', isSigned: true }
    ];

    const renderFormList = () => {
        return (
            <ul>
                {similarForms.map((form) => (
                    <li key={form.name}>
                        {form.name} - {form.isSigned ? 'Complete' : 'Requires Signing'}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Container maxwidthlg='true' style={{
            marginTop: '2vh',
            boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
            padding: '10px',
            backgroundColor: '#F8F8F8',
        }}>
            <div>
                <div>
                    <h3>Forms</h3>
                </div>
                <div className="generic-card">
                    <p>
                        <button onClick={handleShowForm}>
                            {isFormVisible ? 'Close Form' : 'Sign Consent form'}
                        </button>
                        {' '}
                    </p>
                    {isFormSigned ? (
                        <p>You have already signed the consent form.</p>
                    ) : (
                        <div>
                            {isFormVisible && (
                                <div>
                                    <ConsentForm onFormSigned={handleFormSigned} />
                                </div>
                            )}
                        </div>
                    )}
                    <div style={{ padding: "10px" }}>
                        <p>Status: {isFormSigned ? 'Complete' : 'Requires Signing'}</p>
                        <h4>Other Forms</h4>
                        {renderFormList()}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Resource;



