import React, { useState, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import MaterialReactTable from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Send } from '@mui/icons-material';

import rest_api from '../api/boafo';


const Patient = () => {
	const columns = useMemo(
		() => [{
			header: 'Picture',
			id: 'picture',
		},
		{
			header: 'First Name',
			id: 'first_name',
		},
		{
			header: 'Last Name',
			id: 'last_name',
		},
		{
			header: 'Email',
			id: 'email',
		},
		{
			header: 'Phone ',
			id: 'phone_number',
		},
		], [],
	);

	const [patient_obj, set_patient_obj] = useState([null]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const [update_data, set_update_data] = useState([]);

	const patient_data_array = useMemo(() => patient_obj.map(item => {
		const patients = (item);
		return patients
	}), [patient_obj])

	const catchAllPatients = () => {
		rest_api.get(`api/v1/patient?`)
			.then(response => {
				if (response.status === 200 && response != null) {
					setIsLoaded(true);
					set_patient_obj(response.data.patient);
				} else {
					setError(error)
				}
			})
			.catch(error => {
				setError(error)
			})
	};

	// Function for creating a edit patient
	const handlePushPatient = async (row) => {

		const tempData = update_data;
		tempData.push({
			_id: row.row.original._id,
			patient_name: row.row._valuesCache.patient_name,
			patient_description: row.row._valuesCache.patient_description
		});
		set_update_data(update_data);
		try {
			rest_api.put('api/v1/patient', update_data[0])
			// redirecting to patient dashboard
		} catch (error) {
			// Handle Error Here
			console.error(error);
		}

	}

	useEffect(() => {
		catchAllPatients()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (error) {
		return (
			<div className="container">
				<center>
					<div className="error" style={{ color: "red" }}>
						<p>Error message: {error.message}</p>
					</div>
				</center>
			</div>
		);

	} else if (!isLoaded) {
		return (
			<div className="container">
				<center>
					<div className="loading" style={{ color: "#F58634" }}>
						<p>Loading...</p>
					</div>
				</center>
			</div>
		);
	} else {
		return (
			<>
				<div className="container">
					<Stack spacing={2} direction="row">
						<MaterialReactTable
							columns={columns}
							data={patient_data_array}
							enableEditing
							enableRowVirtualization
							onEditRowSubmit={handlePushPatient}
							muiTableContainerProps={{ sx: { maxHeight: '720px', maxWidth: '1200px' } }}
							virtualizerProps={{ overscan: 20 }}
							positionToolbarAlertBanner="bottom"
							enableColumnOrdering
							enableColumnResizing
							renderRowActionMenuItems={({ closeMenu }) => [

								<MenuItem
									key={0}
									onClick={() => {
										// Send email logic...
										closeMenu();
									}}
									sx={{ m: 0 }}
								>
									<ListItemIcon>
										<Send />
									</ListItemIcon>
									Flag
								</MenuItem>,

							]}
						/>

						<NavLink end to='/patient/create'>
							<Button variant="contained" color="success" type='submit'> Add</Button>
						</NavLink>
					</Stack>
				</div>

			</>

		)
	}
}

export default Patient