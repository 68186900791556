import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const Anova = ({ data }) => {
    const [groups, setGroups] = useState([]);
    const [fValue, setFValue] = useState(0);
    const [pValue] = useState(0);

    const mean = (array) => {
        if (!array.length) {
            return 0;
        }
        return array.reduce((a, b) => a + b) / array.length;
    };

    useEffect(() => {
        const groupData = data.reduce((acc, item) => {
            const { group, value } = item;
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push(value);
            return acc;
        }, {});

        setGroups(Object.values(groupData));
    }, [data]);

    useEffect(() => {
        if (!groups.length) {
            return;
        }

        const totalMean = mean(data.map((item) => item.value));
        const groupMeans = groups.map((group) => mean(group));
        // eslint-disable-next-line 
        const groupSizes = groups.map((group) => group.length);

        const sst = groups
            .map((group) =>
                group.map((value) => (value - totalMean) ** 2).reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0);
        const sse = groups
            .map((group, index) => {
                const groupMean = groupMeans[index];
                return group.map((value) => (value - groupMean) ** 2).reduce((a, b) => a + b, 0);
            })
            .reduce((a, b) => a + b, 0);
        const ssr = sst - sse;

        const dfTotal = data.length - 1;
        const dfGroups = groups.length - 1;
        const dfError = dfTotal - dfGroups;

        const msr = ssr / dfGroups;
        const mse = sse / dfError;

        setFValue(msr / mse);
        // eslint-disable-next-line 
    }, [groups]);

    return (
        <div className="generic-card">
            <h3>ANOVA Results</h3>
            <LineChart width={600} height={300} data={data}>
                <XAxis dataKey="group" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
            <p>F Value: {fValue}</p>
            <p>P Value: {pValue}</p>
            <p>
                The F Value represents the ratio of between-group variability to within-group variability.
                A high F Value indicates that there is a significant difference between the groups, while a low F Value indicates that the groups are similar.
            </p>
            <p>
                The P Value represents the probability that the difference between the groups is due to random chance.
                A low P Value (typically below 0.05) indicates that the difference is statistically significant,
                while a high P Value (above 0.05) indicates that the difference is not significant.
            </p>
            <p>
                In this case, if the P Value is below 0.05, we can reject the null hypothesis that the groups have the same mean,
                and conclude that there is a significant difference between the groups.
            </p>
        </div>
    );
};

export default Anova;

