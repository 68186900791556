import React, { useState } from "react";
// Importing material UI components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// Importing material UI icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Coronavirus } from "@mui/icons-material";
import { MedicationLiquid } from "@mui/icons-material";
import ScienceIcon from '@mui/icons-material/Science';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import PrescriptionManager from './PrescriptionManager'
import LabTestManager from './LabTestManager'
import VaccinationTracker from "./VaccinationTracker";
import AllergyTracker from './AllergyTracker'
import Dictation from './Dictation'

const Consulation = ({ patient }) => {
	const [openDictation, setOpenDictation] = useState(false);
	const [openPrescription, setOpenPrescription] = useState(false);
	const [openLabwork, setOpenLabwork] = useState(false);
	const [openInnoculation, setOpenInnoculation] = useState(false);
	const [openAllergy, setOpenAllergy] = useState(false);
	const { id, visits, vitals, appointment, name } = patient;


	const handleDictationClick = () => {
		setOpenDictation(!openDictation);
	};
	const handlePrescriptionClick = () => {
		setOpenPrescription(!openPrescription);
	};
	const handleLabworkClick = () => {
		setOpenLabwork(!openLabwork);
	};
	const handleInnoculationClick = () => {
		setOpenInnoculation(!openInnoculation);
	};
	const handleAllergyClick = () => {
		setOpenAllergy(!openAllergy);
	};

	return (
		<>
			<Container maxwidthlg='true' style={{
				marginTop: '2vh',
				boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
				padding: '10px',
				backgroundColor: '#F8F8F8'
			}}>
				<List
					disablePadding
					component="nav"
					aria-labelledby="nested-list-subheader"
				>
					<ListItem button dense onClick={handleDictationClick}
						sx={{
							bgcolor: '#f7feff',
							border: '0.5px solid #343a40',
							borderRadius: '2%',
							color: 'black',
							fontWeight: '600'
						}}>
						<ListItemIcon sx={{ color: '#50C878' }}>
							<MedicalServicesIcon />
						</ListItemIcon>
						<ListItemText primary="Dictation" />
						{/*code to openDictation and closed list*/}
						{openDictation ? <> <ListItemText secondary={name} />  <ExpandLess /></> : <ExpandMore />}
					</ListItem>
					<Collapse in={openDictation} timeout="auto" unmountOnExit>
						{/*List item are wrapped inside List */}
						<List component="div" disablePadding
							style={{
								color: '#787878',
							}}>
							<Dictation patient={patient} />
						</List>

					</Collapse>
				</List>

				<List
					component="nav"
					aria-labelledby="nested-list-subheader"
				>
					<ListItem button dense onClick={handlePrescriptionClick}
						sx={{
							bgcolor: '#f7feff',
							border: '0.5px solid #343a40',
							borderRadius: '2%',
							color: 'black',
							fontWeight: '600'
						}}>
						<ListItemIcon sx={{ color: 'red' }}>
							<MedicationLiquid />
						</ListItemIcon>
						<ListItemText primary="Prescription" />
						{/*code to openPrescription and closed list*/}
						{openPrescription ? <> <ListItemText secondary={name} />  <ExpandLess /></> : <ExpandMore />}
					</ListItem>
					<Collapse in={openPrescription} timeout="auto" unmountOnExit>
						{/*List item are wrapped inside List */}
						<List component="div" disablePadding
							style={{
								color: '#787878',
							}}>
							<PrescriptionManager patient={patient} />
						</List>

					</Collapse>
				</List>

				<List
					disablePadding
					component="nav"
					aria-labelledby="nested-list-subheader"
				>
					<ListItem button dense onClick={handleLabworkClick}
						sx={{
							bgcolor: '#f7feff',
							border: '0.5px solid #343a40',
							borderRadius: '2%',
							color: 'black',
							fontWeight: '600'
						}}>
						<ListItemIcon sx={{ color: 'orange' }}>
							<ScienceIcon />
						</ListItemIcon>
						<ListItemText primary="Lab Work" />
						{/*code to openLabwork and closed list*/}
						{openLabwork ? <> <ListItemText secondary={name} />  <ExpandLess /></> : <ExpandMore />}
					</ListItem>
					<Collapse in={openLabwork} timeout="auto" unmountOnExit>
						{/*List item are wrapped inside List */}
						<List component="div" disablePadding
							style={{
								color: '#787878',
							}}>
							<LabTestManager patient={patient} />
						</List>

					</Collapse>
				</List>

				<List
					component="nav"
					aria-labelledby="nested-list-subheader"
				>
					<ListItem button dense onClick={handleInnoculationClick}
						sx={{
							bgcolor: '#f7feff',
							border: '0.5px solid #343a40',
							borderRadius: '2%',
							color: 'black',
							fontWeight: '600'
						}}>
						<ListItemIcon sx={{ color: '#318CE7' }}>
							<VaccinesIcon />
						</ListItemIcon>
						<ListItemText primary="Innoculation" />
						{/*code to openInnoculation and closed list*/}
						{openInnoculation ? <> <ListItemText secondary={name} />  <ExpandLess /></> : <ExpandMore />}
					</ListItem>
					<Collapse in={openInnoculation} timeout="auto" unmountOnExit>
						{/*List item are wrapped inside List */}
						<List component="div" disablePadding
							style={{
								color: '#787878',
							}}>
							<VaccinationTracker patient={patient} />
						</List>

					</Collapse>
				</List>

				<List
					disablePadding
					component="nav"
					aria-labelledby="nested-list-subheader"
				>
					<ListItem button dense onClick={handleAllergyClick}
						sx={{
							bgcolor: '#f7feff',
							border: '0.5px solid #343a40',
							borderRadius: '2%',
							color: 'black',
							fontWeight: '600'
						}}>
						<ListItemIcon>
							<Coronavirus />
						</ListItemIcon>
						<ListItemText primary="Allergy" />
						{/*code to openAllergy and closed list*/}
						{openAllergy ? <> <ListItemText secondary={name} />  <ExpandLess /></> : <ExpandMore />}
					</ListItem>
					<Collapse in={openAllergy} timeout="auto" unmountOnExit>
						{/*List item are wrapped inside List */}
						<List component="div" disablePadding
							style={{
								color: '#787878',
							}}>
							<AllergyTracker patient={patient} />
						</List>

					</Collapse>
				</List>
			</Container>
		</>
	);
}
export default Consulation;