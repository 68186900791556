import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container'

const GPT3Response = ({ query }) => {
  const query_str = query;

  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const openai_token = process.env.REACT_APP_OPENAI_API_KEY;

  const fetchData = async (event) => {
    setIsLoading(true);
    try {
      const response = await fetch('https://api.openai.com/v1/engines/text-davinci-003/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': openai_token
        },
        body: JSON.stringify({
          prompt: query_str,
          max_tokens: 128,
          temperature: 0,
        })
      });
      const data = await response.json();
      setResponse(data.choices[0].text);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {

    fetchData()
  }, []);

  return (
    <div>
      <Container maxwidthlg='true' style={{
        marginTop: '2vh',
        boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
        padding: '10px',
        backgroundColor: '#F8F8F8'
      }}>
        {isLoading ? (
          <div>
            <br />
            <p style={{ color: 'gray' }}>loading...</p>
          </div>
        ) : <div
          style={{
            backgroundColor: 'white',
            border: "0.5px solid #343a40",
            padding: "10px",
            fontSize: "13px",
            marginTop: "10px",
            borderRadius: "5px"
          }}> {response} </div>
        }
      </Container>
    </div>
  );
};

export default GPT3Response;
